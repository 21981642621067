import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BETA_MEASUREMENT_ID, PROD_MEASUREMENT_ID } from './constants';
import { Navigation } from './Navigation/Navigation';
import { isProd } from './utils';
ReactGA.initialize(isProd() ? PROD_MEASUREMENT_ID : BETA_MEASUREMENT_ID);

function App() {
    document.body.classList.add('background-red');
    return (
        <HelmetProvider>
            <Box>
                {!isProd() && (
                    <Helmet>
                        <meta name="robots" content="noindex, nofollow" />
                    </Helmet>
                )}
                <Navigation />
            </Box>
        </HelmetProvider>
    );
}

export default App;
