import { makeStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { BLOG_PATH } from '../constants';

interface WidgetProps {
    title: string;
    imageUrl: string;
    description: string;
    id: string;
}

const useStyles = makeStyles((theme) => ({
    card: {
        [theme.breakpoints.down('sm')]: {
            width: 350,
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 300,
            width: '100%',
        },
        margin: 'auto',
        '@media (pointer: fine)': {
            '&:hover': {
                backgroundColor: '#CFD4CF',
                cursor: 'pointer',
            },
        },
    },
}));

export default function RecommendedArticleWidget(props: WidgetProps) {
    const classes = useStyles();
    return (
        <Link to={`/${BLOG_PATH}/post?id=${props.id}`} style={{ textDecoration: 'none' }}>
            <Card className={classes.card}>
                <CardMedia sx={{ height: 140 }} image={props.imageUrl} title={props.title} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.description}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
}
