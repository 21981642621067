import '@fontsource/montserrat';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { generateArray } from './ArrayFunctions';
import { BottomButton } from './BottomButton';
import {
    ARRAY_Y,
    CANVAS_HEIGHT_PERCENTAGE,
    CANVAS_WIDTH_PX,
    FONT_SIZE,
    INITIAL_X,
    RECTANGLE_HEIGHT,
    RECTANGLE_WIDTH,
} from './Constants';
import { InfoModal } from './InfoModal';
import { SettingsModal } from './SettingsModal';
import { bubbleSort, insertionSort, quickSort } from './SortingFunctions';
import { AlgorithmStep, ArrayElement, SortSpeeds, States, TraversalTypes } from './Types';

const NEIGHBOR_BEING_CHECKED_COLOR = '#FFA07A';
const CHECKING_NEIGHBORS_COLOR = '#CECEF0';
const VISITED_COLOR = '#AFEEEE';
const GOAL_COLOR = '#90EE90';
const RECTANGLE_RADIUS = 5;

export function TestVisualizer() {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const requestRef = useRef<number | null>(null);
    const [sortType, setSortType] = useState(TraversalTypes.QUICK_SORT);
    const [seed, setSeed] = useState(Math.floor(Math.random() * 1000000000));
    const array = useRef(generateArray(seed));
    const i = useRef(getI());
    const j = useRef(getJ());
    const iElement = useRef<ArrayElement>({
        x: INITIAL_X + i.current * RECTANGLE_WIDTH,
        y: ARRAY_Y + RECTANGLE_HEIGHT,
        color: 'white',
        value: -1,
    });
    const jElement = useRef<ArrayElement>({
        x: INITIAL_X + j.current * RECTANGLE_WIDTH,
        y: ARRAY_Y + RECTANGLE_HEIGHT,
        color: 'white',
        value: -2,
    });
    const algorithm = useRef<AlgorithmStep[]>(insertionSort(array.current));
    const [isRunning, setIsRunning] = useState(false);
    const algorithmStepIndex = useRef(0);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [reset, setReset] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [sortingSpeedLabel, setSortingSpeedLabel] = useState(SortSpeeds.MEDIUM);

    function getI() {
        return sortType === TraversalTypes.INSERTION_SORT ? 1 : 0;
    }

    function getJ() {
        return sortType === TraversalTypes.BUBBLE_SORT
            ? 1
            : sortType === TraversalTypes.INSERTION_SORT
            ? 0
            : array.current.length - 1;
    }

    function handleToggleAnimation() {
        setIsRunning((prev) => !prev);
    }

    function isEnd() {
        if (algorithmStepIndex.current === algorithm.current.length - 1) {
            return true;
        }
        return false;
    }

    function colorWholeArray() {
        const tempArray = [...array.current];
        for (const element of tempArray) {
            element.color = GOAL_COLOR;
        }
        array.current = tempArray;
    }

    function getInitialIElement() {
        return {
            x: INITIAL_X + i.current * RECTANGLE_WIDTH,
            y: ARRAY_Y + RECTANGLE_HEIGHT,
            color: 'white',
            value: -1,
        };
    }

    function getInitialJElement() {
        return {
            x: INITIAL_X + j.current * RECTANGLE_WIDTH,
            y: ARRAY_Y + RECTANGLE_HEIGHT,
            color: 'white',
            value: -2,
        };
    }

    function getSpeed() {
        return sortingSpeedLabel === SortSpeeds.VERY_FAST
            ? RECTANGLE_WIDTH
            : sortingSpeedLabel === SortSpeeds.FAST
            ? RECTANGLE_WIDTH / 2
            : sortingSpeedLabel === SortSpeeds.MEDIUM
            ? RECTANGLE_WIDTH / 4
            : RECTANGLE_WIDTH / 8;
    }

    function sortArray(array: ArrayElement[]) {
        return sortType === TraversalTypes.QUICK_SORT
            ? quickSort(array)
            : sortType === TraversalTypes.INSERTION_SORT
            ? insertionSort(array)
            : bubbleSort(array);
    }

    useEffect(() => {
        document.title = 'Sorting Algorithm Visualizer - Mike Brauninger';
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    useEffect(() => {
        array.current = generateArray(seed);
        algorithm.current = sortArray(array.current);
        algorithmStepIndex.current = 0;
        i.current = getI();
        j.current = getJ();
        iElement.current = getInitialIElement();
        jElement.current = getInitialJElement();
        setReset((prev) => !prev);
    }, [sortType]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        function drawArrayElement(element: ArrayElement) {
            if (!context) return;
            context.beginPath();
            context.roundRect(element.x, element.y, RECTANGLE_WIDTH, RECTANGLE_HEIGHT, RECTANGLE_RADIUS);
            context.stroke();
            context.fillStyle = element.color;
            context.fill();
            context.fillStyle = 'black';
            context.font = `${FONT_SIZE}px Verdana`;
            context.fillText(
                element.value === -1 ? 'i' : element.value === -2 ? 'j' : element.value.toString(),
                element.x +
                    (element.value > 99
                        ? RECTANGLE_WIDTH / 8
                        : element.value > 9
                        ? RECTANGLE_WIDTH / 4
                        : RECTANGLE_WIDTH / 2.7),
                element.y + RECTANGLE_HEIGHT / 2,
            );
        }

        if (!canvas || !context) return;

        const devicePixelRatio = window.devicePixelRatio || 1;

        const width = canvas.clientWidth * devicePixelRatio;
        const height = canvas.clientHeight * devicePixelRatio;

        canvas.width = width;
        canvas.height = height;

        context.scale(devicePixelRatio, devicePixelRatio);
        const render = () => {
            context.clearRect(0, 0, width, height);
            array.current.forEach((element) => {
                drawArrayElement(element);
            });

            drawArrayElement(iElement.current);
            drawArrayElement(jElement.current);
            if (isRunning) {
                requestRef.current = requestAnimationFrame(render);
                if (
                    sortType === TraversalTypes.QUICK_SORT &&
                    algorithm.current[algorithmStepIndex.current].state !== States.RESETTING_I_AND_J
                ) {
                    for (const element of array.current) {
                        if (element.color === 'white') {
                            element.color = CHECKING_NEIGHBORS_COLOR;
                            break;
                        } else if (element.color === CHECKING_NEIGHBORS_COLOR) {
                            break;
                        }
                    }
                }
                if (sortType === TraversalTypes.INSERTION_SORT) {
                    for (const element of array.current) {
                        if (
                            (element.x === iElement.current.x &&
                                algorithm.current[algorithmStepIndex.current].state === States.RAISING_ELEMENTS) ||
                            (element.x < iElement.current.x && element.y < ARRAY_Y)
                        ) {
                            element.color = NEIGHBOR_BEING_CHECKED_COLOR;
                        } else if (element.x < iElement.current.x) {
                            element.color = CHECKING_NEIGHBORS_COLOR;
                        }
                    }
                }
                let element1StartIndex = algorithm.current[algorithmStepIndex.current].element1StartIndex;
                let element2StartIndex = algorithm.current[algorithmStepIndex.current].element2StartIndex;
                if (
                    algorithm.current[algorithmStepIndex.current].state === States.MOVING_I_AND_J ||
                    algorithm.current[algorithmStepIndex.current].state === States.RESETTING_I_AND_J
                ) {
                    let iSet = false;
                    let jSet = false;
                    if (iElement.current.x !== algorithm.current[algorithmStepIndex.current].element1Destination) {
                        const tempIElement = { ...iElement.current };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element1Destination > iElement.current.x
                                ? 1
                                : -1;
                        tempIElement.x = tempIElement.x + getSpeed() * direction;
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempIElement.x < algorithm.current[algorithmStepIndex.current].element1Destination) ||
                            (direction === 1 &&
                                tempIElement.x > algorithm.current[algorithmStepIndex.current].element1Destination)
                        ) {
                            tempIElement.x = algorithm.current[algorithmStepIndex.current].element1Destination;
                        }
                        iElement.current = tempIElement;
                    } else {
                        iSet = true;
                    }
                    if (jElement.current.x !== algorithm.current[algorithmStepIndex.current].element2Destination) {
                        const tempJElement = { ...jElement.current };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element2Destination > jElement.current.x
                                ? 1
                                : -1;
                        tempJElement.x = tempJElement.x + getSpeed() * direction;
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempJElement.x < algorithm.current[algorithmStepIndex.current].element2Destination) ||
                            (direction === 1 &&
                                tempJElement.x > algorithm.current[algorithmStepIndex.current].element2Destination)
                        ) {
                            tempJElement.x = algorithm.current[algorithmStepIndex.current].element2Destination;
                        }
                        jElement.current = tempJElement;
                    } else {
                        jSet = true;
                    }
                    if (iSet && jSet) {
                        if (
                            sortType === TraversalTypes.BUBBLE_SORT &&
                            algorithmStepIndex.current !== algorithm.current.length - 1 &&
                            algorithm.current[algorithmStepIndex.current + 1].element1DestinationIndex === 0
                        ) {
                            array.current[
                                algorithm.current[algorithmStepIndex.current].element2DestinationIndex
                            ].color = GOAL_COLOR;
                        }
                        if (
                            sortType === TraversalTypes.QUICK_SORT &&
                            algorithm.current[algorithmStepIndex.current].state === States.MOVING_I_AND_J &&
                            (algorithm.current[algorithmStepIndex.current].element1DestinationIndex === 0 ||
                                array.current[
                                    algorithm.current[algorithmStepIndex.current].element1DestinationIndex - 1
                                ].color === GOAL_COLOR)
                        ) {
                            array.current[
                                algorithm.current[algorithmStepIndex.current].element1DestinationIndex
                            ].color = GOAL_COLOR;
                            if (
                                algorithm.current[algorithmStepIndex.current].element2DestinationIndex ===
                                    array.current.length - 1 ||
                                array.current[
                                    algorithm.current[algorithmStepIndex.current].element2DestinationIndex + 1
                                ].color === GOAL_COLOR
                            ) {
                                array.current[
                                    algorithm.current[algorithmStepIndex.current].element2DestinationIndex
                                ].color = GOAL_COLOR;
                            }
                        }
                        if (
                            sortType === TraversalTypes.QUICK_SORT &&
                            algorithm.current[algorithmStepIndex.current].state === States.MOVING_I_AND_J &&
                            algorithm.current[algorithmStepIndex.current].element2DestinationIndex === 0
                        ) {
                            array.current[
                                algorithm.current[algorithmStepIndex.current].element2DestinationIndex
                            ].color = GOAL_COLOR;
                        }
                        if (isEnd()) {
                            if (requestRef.current) {
                                colorWholeArray();
                                cancelAnimationFrame(requestRef.current);
                            }
                            setIsRunning(false);
                            return;
                        }
                        algorithmStepIndex.current += 1;
                    }
                } else if (
                    algorithm.current[algorithmStepIndex.current].state === States.RAISING_ELEMENTS ||
                    algorithm.current[algorithmStepIndex.current].state === States.LOWERING_ELEMENTS
                ) {
                    let element1Set = false;
                    let element2Set = false;
                    if (
                        array.current[algorithm.current[algorithmStepIndex.current].element1StartIndex].y !==
                        algorithm.current[algorithmStepIndex.current].element1Destination
                    ) {
                        const tempElement1 = { ...array.current[element1StartIndex] };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element1Destination > tempElement1.y ? 1 : -1;
                        tempElement1.y = tempElement1.y + getSpeed() * direction;
                        if (sortType === TraversalTypes.BUBBLE_SORT) {
                            tempElement1.color = VISITED_COLOR;
                        }
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempElement1.y < algorithm.current[algorithmStepIndex.current].element1Destination) ||
                            (direction === 1 &&
                                tempElement1.y > algorithm.current[algorithmStepIndex.current].element1Destination)
                        ) {
                            tempElement1.y = algorithm.current[algorithmStepIndex.current].element1Destination;
                        }
                        array.current[element1StartIndex] = tempElement1;
                    } else {
                        element1Set = true;
                    }
                    if (
                        array.current[algorithm.current[algorithmStepIndex.current].element2StartIndex].y !==
                        algorithm.current[algorithmStepIndex.current].element2Destination
                    ) {
                        const tempElement2 = { ...array.current[element2StartIndex] };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element2Destination > tempElement2.y ? 1 : -1;
                        tempElement2.y = tempElement2.y + 2 * getSpeed() * direction;
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempElement2.y < algorithm.current[algorithmStepIndex.current].element2Destination) ||
                            (direction === 1 &&
                                tempElement2.y > algorithm.current[algorithmStepIndex.current].element2Destination)
                        ) {
                            tempElement2.y = algorithm.current[algorithmStepIndex.current].element2Destination;
                        }
                        array.current[element2StartIndex] = tempElement2;
                    } else {
                        element2Set = true;
                    }
                    if (element1Set && element2Set) {
                        if (algorithm.current[algorithmStepIndex.current].state === States.LOWERING_ELEMENTS) {
                            let tempArray = [...array.current];
                            if (sortType === TraversalTypes.INSERTION_SORT) {
                                let tempArray2: ArrayElement[] = [];
                                let iElement =
                                    tempArray[algorithm.current[algorithmStepIndex.current].element2StartIndex];
                                for (
                                    let k = 0;
                                    k < algorithm.current[algorithmStepIndex.current].element1StartIndex;
                                    k++
                                ) {
                                    tempArray2.push(tempArray[k]);
                                }
                                tempArray2.push(iElement);
                                for (
                                    let k = algorithm.current[algorithmStepIndex.current].element1StartIndex;
                                    k < algorithm.current[algorithmStepIndex.current].element2StartIndex;
                                    k++
                                ) {
                                    tempArray2.push(tempArray[k]);
                                }
                                for (
                                    let k = algorithm.current[algorithmStepIndex.current].element2StartIndex + 1;
                                    k < tempArray.length;
                                    k++
                                ) {
                                    tempArray2.push(tempArray[k]);
                                }
                                tempArray = tempArray2;
                            } else {
                                let temp =
                                    tempArray[algorithm.current[algorithmStepIndex.current].element2DestinationIndex];
                                tempArray[algorithm.current[algorithmStepIndex.current].element2DestinationIndex] =
                                    tempArray[algorithm.current[algorithmStepIndex.current].element1DestinationIndex];
                                tempArray[algorithm.current[algorithmStepIndex.current].element1DestinationIndex] =
                                    temp;
                                if (
                                    sortType === TraversalTypes.QUICK_SORT &&
                                    iElement.current.x >= jElement.current.x
                                ) {
                                    tempArray[
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex
                                    ].color = GOAL_COLOR;
                                    if (algorithm.current[algorithmStepIndex.current].element1DestinationIndex === 1) {
                                        tempArray[0].color = GOAL_COLOR;
                                    }
                                    if (
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex ===
                                        array.current.length - 2
                                    ) {
                                        tempArray[array.current.length - 1].color = GOAL_COLOR;
                                    }
                                    if (
                                        iElement.current.x >= jElement.current.x &&
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex <
                                            tempArray.length - 2 &&
                                        tempArray[
                                            algorithm.current[algorithmStepIndex.current].element1DestinationIndex + 2
                                        ].color === GOAL_COLOR
                                    ) {
                                        tempArray[
                                            algorithm.current[algorithmStepIndex.current].element1DestinationIndex + 1
                                        ].color = GOAL_COLOR;
                                    }
                                    if (
                                        iElement.current.x >= jElement.current.x &&
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex > 1 &&
                                        tempArray[
                                            algorithm.current[algorithmStepIndex.current].element1DestinationIndex - 2
                                        ].color === GOAL_COLOR
                                    ) {
                                        tempArray[
                                            algorithm.current[algorithmStepIndex.current].element1DestinationIndex - 1
                                        ].color = GOAL_COLOR;
                                    }
                                }
                                if (sortType === TraversalTypes.BUBBLE_SORT) {
                                    tempArray[
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex
                                    ].color = 'white';
                                }
                                if (
                                    sortType === TraversalTypes.BUBBLE_SORT &&
                                    (algorithm.current[algorithmStepIndex.current].element1DestinationIndex ===
                                        tempArray.length - 1 ||
                                        tempArray[
                                            algorithm.current[algorithmStepIndex.current].element1DestinationIndex + 1
                                        ].color === GOAL_COLOR)
                                ) {
                                    tempArray[
                                        algorithm.current[algorithmStepIndex.current].element1DestinationIndex
                                    ].color = GOAL_COLOR;
                                }
                            }
                            array.current = tempArray;
                        }
                        if (isEnd()) {
                            if (requestRef.current) {
                                colorWholeArray();
                                cancelAnimationFrame(requestRef.current);
                            }
                            setIsRunning(false);
                            return;
                        }
                        algorithmStepIndex.current += 1;
                    }
                } else if (algorithm.current[algorithmStepIndex.current].state === States.SWITCHING_ELEMENTS) {
                    let element1Set = false;
                    let element2Set = false;
                    if (
                        array.current[element1StartIndex].x !==
                        algorithm.current[algorithmStepIndex.current].element1Destination
                    ) {
                        const tempElement1 = { ...array.current[element1StartIndex] };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element1Destination > tempElement1.x ? 1 : -1;
                        tempElement1.x = tempElement1.x + getSpeed() * direction;
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempElement1.x < algorithm.current[algorithmStepIndex.current].element1Destination) ||
                            (direction === 1 &&
                                tempElement1.x > algorithm.current[algorithmStepIndex.current].element1Destination)
                        ) {
                            tempElement1.x = algorithm.current[algorithmStepIndex.current].element1Destination;
                        }
                        array.current[element1StartIndex] = tempElement1;
                    } else {
                        element1Set = true;
                    }
                    if (
                        array.current[element2StartIndex].x !==
                        algorithm.current[algorithmStepIndex.current].element2Destination
                    ) {
                        const tempElement2 = { ...array.current[element2StartIndex] };
                        const direction =
                            algorithm.current[algorithmStepIndex.current].element2Destination > tempElement2.x ? 1 : -1;
                        tempElement2.x = tempElement2.x + getSpeed() * direction;
                        // Case where we switched speeds to a bad modulo
                        if (
                            (direction === -1 &&
                                tempElement2.x < algorithm.current[algorithmStepIndex.current].element2Destination) ||
                            (direction === 1 &&
                                tempElement2.x > algorithm.current[algorithmStepIndex.current].element2Destination)
                        ) {
                            tempElement2.x = algorithm.current[algorithmStepIndex.current].element2Destination;
                        }
                        array.current[element2StartIndex] = tempElement2;
                    } else {
                        element2Set = true;
                    }
                    if (element1Set && element2Set) {
                        algorithmStepIndex.current += 1;
                    }
                } else if (algorithm.current[algorithmStepIndex.current].state === States.SHIFTING_ARRAY) {
                    let element1Set = false;
                    if (
                        array.current[algorithm.current[algorithmStepIndex.current].element1StartIndex].x !==
                        algorithm.current[algorithmStepIndex.current].element1Destination
                    ) {
                        const tempArray = [...array.current];
                        for (
                            let i = algorithm.current[algorithmStepIndex.current].element1StartIndex;
                            i < algorithm.current[algorithmStepIndex.current].element2StartIndex;
                            i++
                        ) {
                            tempArray[i].x = tempArray[i].x + getSpeed();
                        }
                        array.current = tempArray;
                    } else {
                        element1Set = true;
                    }
                    if (element1Set) {
                        algorithmStepIndex.current += 1;
                    }
                }
            }
        };

        requestRef.current = requestAnimationFrame(render);
        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [isRunning, reset, sortingSpeedLabel]);

    return (
        <>
            <Helmet>
                <title>Sorting Algorithm Visualizer - Mike Brauninger</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    overflowX: 'auto',
                }}
            >
                <InfoModal modalOpen={infoModalOpen} setModalOpen={setInfoModalOpen} />
                <SettingsModal
                    modalOpen={settingsModalOpen}
                    setModalOpen={setSettingsModalOpen}
                    sortType={sortType}
                    setSortType={setSortType}
                    sortingSpeedLabel={sortingSpeedLabel}
                    setSortingSpeedLabel={setSortingSpeedLabel}
                    setIsRunning={setIsRunning}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant={window.innerWidth <= 768 ? 'h3' : 'h2'}
                        sx={{ position: 'relative', left: window.innerWidth <= 768 ? 10 : 0 }}
                    >
                        Sorting Algorithm Visualizer
                    </Typography>
                    <Button
                        onClick={() => setInfoModalOpen(true)}
                        sx={{
                            position: 'relative',
                            left: window.innerWidth <= 768 ? -10 : 20,
                            border: '1px solid black',
                            borderRadius: 100,
                            backgroundColor: 'white',
                        }}
                    >
                        <QuestionMarkIcon sx={{ width: 50, height: 50, color: 'black' }} />
                    </Button>
                </div>
                <Box
                    sx={{
                        width: `${CANVAS_WIDTH_PX}px`,
                        height: `${CANVAS_HEIGHT_PERCENTAGE}vh`,
                        mx: 'auto',
                        paddingBottom: 6,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: 35, right: 10 }}>
                        <Button
                            style={{
                                height: 25,
                                fontSize: 16,
                                position: 'relative',
                            }}
                            variant="contained"
                            onClick={() => {
                                const seedVal = Math.floor(Math.random() * 1000000000);
                                array.current = generateArray(seedVal);
                                setSeed(seedVal);
                                algorithm.current = sortArray(array.current);
                                setIsRunning(false);
                                algorithmStepIndex.current = 0;
                                i.current = getI();
                                j.current = getJ();
                                iElement.current = getInitialIElement();
                                jElement.current = getInitialJElement();
                                setReset((prev) => !prev);
                            }}
                        >
                            New Array
                        </Button>
                    </Box>
                    <canvas
                        ref={canvasRef}
                        style={{
                            width: `${100}%`,
                            height: `${100}%`,
                            backgroundColor: 'lightblue',
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                        }}
                    />
                </Box>
                <Stack direction={'row'} spacing={2} paddingBottom={2}>
                    <BottomButton
                        text="Start"
                        func={() => {
                            handleToggleAnimation();
                        }}
                    />
                    <BottomButton
                        text="Reset"
                        func={() => {
                            setIsRunning(false);
                            array.current = generateArray(seed);
                            algorithmStepIndex.current = 0;
                            i.current = getI();
                            j.current = getJ();
                            iElement.current = getInitialIElement();
                            jElement.current = getInitialJElement();
                            setReset((prev) => !prev);
                        }}
                    />
                    <BottomButton
                        text="Settings"
                        func={() => {
                            setSettingsModalOpen(true);
                        }}
                    />
                </Stack>
            </Box>
        </>
    );
}
