import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { PATHFINDER_PROJECT_PATH, SORTING_ALG_PROJECT_PATH, TREE_PROJECT_PATH } from '../constants';

const useStyles = makeStyles({
    menuItem: {
        color: 'black',
        '&:hover': {
            color: 'green',
        },
        position: 'relative',
        textDecoration: 'none',
    },
});

export const DropdownMenu = () => {
    const [isMouseInButton, setIsMouseInButton] = React.useState(false);
    const [isMouseInList, setIsMouseInList] = React.useState(false);
    const classes = useStyles();

    const handleMouseEnterButton = () => {
        setIsMouseInButton(true);
    };

    const handleMouseLeaveButton = () => {
        setTimeout(() => {
            setIsMouseInButton(false);
        }, 200);
    };

    const handleMouseEnterList = () => {
        setIsMouseInList(true);
    };

    const handleMouseLeaveList = () => {
        setTimeout(() => {
            setIsMouseInList(false);
        }, 100);
    };

    const menu = (
        <div style={{ position: 'absolute', top: 60, zIndex: 10 }}>
            <div
                onMouseEnter={handleMouseEnterList}
                onMouseLeave={handleMouseLeaveList}
                style={{
                    backgroundColor: 'white',
                    width: 260,
                    borderRadius: 5,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
                }}
            >
                <Link to={PATHFINDER_PROJECT_PATH} className={classes.menuItem}>
                    <MenuItem>
                        <Typography style={{ paddingTop: 5 }}>Pathfinder</Typography>
                    </MenuItem>
                </Link>
                <Link to={TREE_PROJECT_PATH} className={classes.menuItem}>
                    <MenuItem>
                        <Typography>Binary Tree Traverser</Typography>
                    </MenuItem>
                </Link>
                <Link to={SORTING_ALG_PROJECT_PATH} className={classes.menuItem}>
                    <MenuItem>
                        <Typography style={{ paddingBottom: 5 }}>Sorting Algorithm Visualizer</Typography>
                    </MenuItem>
                </Link>
            </div>
        </div>
    );

    return (
        <>
            <Button
                onMouseEnter={handleMouseEnterButton}
                onMouseMove={handleMouseEnterButton}
                onMouseLeave={handleMouseLeaveButton}
            >
                <Typography variant="h6" color="black" fontSize={30}>
                    Projects
                </Typography>
            </Button>
            {(isMouseInButton || isMouseInList) && menu}
        </>
    );
};

export default DropdownMenu;
