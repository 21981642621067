import seedrandom from 'seedrandom';
import { ARRAY_Y, CANVAS_WIDTH_PERCENTAGE, INITIAL_X, RECTANGLE_WIDTH } from './Constants';
import { ArrayElement } from './Types';

export function generateArray(seed: number): ArrayElement[] {
    const rng = seedrandom(seed.toString());
    const width = (CANVAS_WIDTH_PERCENTAGE / 100) * window.innerWidth;
    const output = [];
    let i = 0;
    while (INITIAL_X + RECTANGLE_WIDTH * i + RECTANGLE_WIDTH <= width - RECTANGLE_WIDTH / 2) {
        output.push({
            x: RECTANGLE_WIDTH * i + INITIAL_X,
            y: ARRAY_Y,
            color: 'white',
            value: Math.floor(rng() * 999),
        });
        i++;
    }
    return output;
}
