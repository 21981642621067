export function isProd() {
    return window.location.hostname === 'mikebrauninger.com';
}

export function getS3Url() {
    if (isProd()) {
        return 'https://personal-website-blogging-bucket.s3.amazonaws.com';
    } else {
        return 'https://personal-website-blogging-bucket-beta.s3.amazonaws.com';
    }
}
