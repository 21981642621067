import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { SortSpeeds, TraversalTypes } from './Types';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 50,
    backgroundColor: '#ebfce3',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface ModalProps {
    modalOpen: boolean;
    setModalOpen: Function;
    sortType: string;
    setSortType: Function;
    sortingSpeedLabel: string;
    setSortingSpeedLabel: Function;
    setIsRunning: Function;
}

export const SettingsModal = (props: ModalProps) => {
    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => props.setModalOpen(false)}
                    aria-label="close"
                    style={{ position: 'absolute', top: 0, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ position: 'relative', bottom: 15 }}>
                    <Box>
                        <Typography sx={{ display: 'inline' }}>Traversal Type:</Typography>
                        <Select
                            style={{
                                marginRight: 5,
                                height: 30,
                                width: 110,
                                fontSize: 10,
                                backgroundColor: 'white',
                                position: 'relative',
                                left: 7,
                                marginBottom: 13,
                            }}
                            value={props.sortType}
                            label="SortType"
                            onChange={(event) => {
                                props.setIsRunning(false);
                                props.setSortType(event.target.value);
                            }}
                            MenuProps={{
                                style: {
                                    height: 400, // Set the maximum height of the menu
                                },
                            }}
                        >
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.BUBBLE_SORT}>
                                BubbleSort
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.QUICK_SORT}>
                                QuickSort
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.INSERTION_SORT}>
                                InsertionSort
                            </MenuItem>
                        </Select>
                        <Box sx={{ paddingBottom: 5 }}>
                            <Typography sx={{ display: 'inline' }}>Sorting Speed:</Typography>
                            <Select
                                style={{
                                    marginRight: 5,
                                    height: 30,
                                    width: 110,
                                    fontSize: 10,
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    left: 7,
                                    marginBottom: 13,
                                }}
                                value={props.sortingSpeedLabel}
                                label="Speed"
                                onChange={(event) => {
                                    props.setSortingSpeedLabel(event.target.value);
                                }}
                                MenuProps={{
                                    style: {
                                        height: 400, // Set the maximum height of the menu
                                    },
                                }}
                            >
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.VERY_FAST}>
                                    Very Fast
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.FAST}>
                                    Fast
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.MEDIUM}>
                                    Medium
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.SLOW}>
                                    Slow
                                </MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
