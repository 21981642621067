import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Hidden } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { BASE_API_PATH, BLOG_PATH } from '../constants';
import { getS3Url } from '../utils';

type BlogPostList = {
    s3Key: string;
    title: string;
    timestamp: number;
    id: string;
    description: string;
}[];

interface Response {
    moreItems: boolean;
    items: BlogPostList;
}

enum MethodType {
    LESS_THAN = 'less_than',
    GREATER_THAN = 'greater_than',
}

const useStyles = makeStyles((theme) => ({
    table: {
        maxWidth: 1250,
        margin: 'auto',
    },
    image: {
        width: '150px',
    },
    tableRow: {
        '@media (pointer: fine)': {
            '&:hover': {
                backgroundColor: '#CFD4CF',
                cursor: 'pointer',
            },
        },
    },
}));

function timestampToHumanReadableDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

export function Blog() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [content, setContent] = useState<BlogPostList>([]);
    const [newestTimestamp, setNewestTimestamp] = useState(1000000000000);
    const [oldestTimestamp, setOldestTimestamp] = useState(0);

    const [backButtonDisabled, setBackButtonDiabled] = useState(true);
    const [nextButtonDisabled, setNextButtonDiabled] = useState(false);
    const [error, setError] = useState(false);

    function handleBackButtonClick() {
        setNextButtonDiabled(false);
        getBlogs(newestTimestamp, MethodType.GREATER_THAN);
    }

    function handleNextButtonClick() {
        setBackButtonDiabled(false);
        getBlogs(oldestTimestamp, MethodType.LESS_THAN);
    }

    async function getBlogs(timestamp: number, methodType: MethodType) {
        try {
            const response = await fetch(`${BASE_API_PATH}/blog?last_timestamp=${timestamp}&method_type=${methodType}`);
            const content: Response = await response.json();
            setContent(content.items);
            if (methodType === MethodType.GREATER_THAN) {
                if (content.moreItems) {
                    setBackButtonDiabled(false);
                } else {
                    setBackButtonDiabled(true);
                }
            } else {
                if (content.moreItems) {
                    setNextButtonDiabled(false);
                } else {
                    setNextButtonDiabled(true);
                }
            }
            setNewestTimestamp(content.items[0].timestamp);
            setOldestTimestamp(content.items[content.items.length - 1].timestamp);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'Blog - Mike Brauninger';
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    useEffect(() => {
        getBlogs(newestTimestamp, MethodType.LESS_THAN);
    }, []);
    return (
        <>
            <Helmet>
                <title>Blog - Mike Brauninger</title>
            </Helmet>
            <Box>
                <Typography variant="h3" display={'flex'} justifyContent={'center'} paddingBottom={2}>
                    Posts
                </Typography>
                {loading ? (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography variant={'h4'} textAlign={'center'}>
                        {`Encountered error when retrieving data.`}
                    </Typography>
                ) : (
                    <Box>
                        <Box paddingBottom={3} paddingRight={1} paddingLeft={1}>
                            <Table className={classes.table}>
                                <TableBody>
                                    {content.map((post, index) => (
                                        <TableRow
                                            key={index}
                                            className={classes.tableRow}
                                            onClick={() => {
                                                navigate(`/${BLOG_PATH}/post?id=${post.id}`);
                                            }}
                                        >
                                            <TableCell>
                                                <Hidden smUp>
                                                    <Typography
                                                        variant="h6"
                                                        style={{ fontWeight: 'bold', paddingBottom: 5 }}
                                                    >
                                                        {post.title}
                                                    </Typography>
                                                </Hidden>
                                                <Box display="flex" alignItems="center">
                                                    <Box display="flex" alignItems="center" flexDirection={'column'}>
                                                        <img
                                                            src={`${getS3Url()}/${post.s3Key}`}
                                                            alt={post.s3Key}
                                                            className={classes.image}
                                                        />
                                                        <Hidden mdUp>
                                                            <Typography variant="body1">
                                                                {timestampToHumanReadableDate(post.timestamp)}
                                                            </Typography>
                                                        </Hidden>
                                                    </Box>
                                                    <Stack spacing={1}>
                                                        <Hidden smDown>
                                                            <Typography
                                                                variant="h5"
                                                                style={{ paddingLeft: 20, fontWeight: 'bold' }}
                                                            >
                                                                {post.title}
                                                            </Typography>
                                                            <Typography style={{ paddingLeft: 20 }}>
                                                                {post.description}
                                                            </Typography>
                                                        </Hidden>
                                                        <Hidden smUp>
                                                            <Typography
                                                                style={{
                                                                    paddingLeft: 20,
                                                                    fontSize: 13,
                                                                    position: 'relative',
                                                                    bottom: 10,
                                                                }}
                                                            >
                                                                {post.description}
                                                            </Typography>
                                                        </Hidden>
                                                    </Stack>
                                                </Box>
                                            </TableCell>
                                            <Hidden mdDown>
                                                <TableCell>
                                                    <Typography variant="body1">
                                                        {timestampToHumanReadableDate(post.timestamp)}
                                                    </Typography>
                                                </TableCell>
                                            </Hidden>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box
                            maxWidth={1250}
                            mx="auto"
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'end'}
                            paddingBottom={3}
                            paddingRight={2}
                        >
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="outlined"
                                    onClick={handleBackButtonClick}
                                    disabled={backButtonDisabled}
                                    startIcon={<ArrowBack />}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleNextButtonClick}
                                    disabled={nextButtonDisabled}
                                    endIcon={<ArrowForward />}
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
}
