export const NODE_RADIUS = 15;
export const DEFAULT_NUM_NODES = window.innerWidth <= 768 ? 50 : 90;
export const CANVAS_WIDTH_PERCENTAGE = window.innerWidth <= 768 ? 93.5 : 40;
export const CANVAS_WIDTH_PX = window.innerWidth <= 768 ? 0.935 * window.innerWidth : 0.4 * window.innerWidth;
export const CANVAS_HEIGHT_PERCENTAGE = window.innerWidth <= 768 ? 70 : 80;
export const DISPLAY_WIDTH = (window.innerWidth * CANVAS_WIDTH_PERCENTAGE) / 100;
export const DISPLAY_HEIGHT = (window.innerHeight * CANVAS_HEIGHT_PERCENTAGE) / 100;
export const FAST_DELAY = 50;
export const MEDIUM_DELAY = 100;
export const SLOW_DELAY = 1000;
export const MAX_LISTED_TRAVERSAL_ITEMS = 30;
export const Y_OFFSET = 40;
