import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { SortSpeeds, TraversalTypes } from './Types';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 210,
    backgroundColor: '#ebfce3',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface ModalProps {
    modalOpen: boolean;
    setModalOpen: Function;
    numNodes: number;
    selectedTraversal: string;
    setSelectedTraversal: Function;
    sortingSpeedLabel: string;
    setSortingSpeedLabel: Function;
    setNumNodes: Function;
}

export const SettingsModal = (props: ModalProps) => {
    const handleInput = (event: any) => {
        const inputValue = event.target.value;
        const numericValue = parseInt(inputValue);
        props.setNumNodes(numericValue);
    };

    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => props.setModalOpen(false)}
                    aria-label="close"
                    style={{ position: 'absolute', top: 0, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ position: 'relative', bottom: 15 }}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography>Node Count:</Typography>
                        <Input
                            style={{
                                position: 'relative',
                                left: 7,
                                marginBottom: 13,
                                height: 25,
                                width: 60,
                                backgroundColor: 'white',
                                borderRadius: 3,
                                border: '1px solid lightgrey',
                                paddingLeft: 7,
                                paddingBottom: 12,
                                paddingTop: 12,
                            }}
                            disableUnderline
                            type="number"
                            value={props.numNodes}
                            onChange={handleInput}
                        />
                    </Box>
                    <Box>
                        <Typography sx={{ display: 'inline' }}>Traversal Type:</Typography>
                        <Select
                            style={{
                                marginRight: 5,
                                height: 30,
                                width: 100,
                                fontSize: 10,
                                backgroundColor: 'white',
                                position: 'relative',
                                left: 7,
                                marginBottom: 13,
                            }}
                            value={props.selectedTraversal}
                            label="TraversalType"
                            onChange={(event) => {
                                props.setSelectedTraversal(event.target.value);
                            }}
                            MenuProps={{
                                style: {
                                    height: 400, // Set the maximum height of the menu
                                },
                            }}
                        >
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.IN_ORDER}>
                                InOrder
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.PRE_ORDER}>
                                PreOrder
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.POST_ORDER}>
                                PostOrder
                            </MenuItem>
                            <MenuItem sx={{ fontSize: 10 }} value={TraversalTypes.BFS}>
                                Breadth-First Search
                            </MenuItem>
                        </Select>
                        <Box sx={{ paddingBottom: 5 }}>
                            <Typography sx={{ display: 'inline' }}>Sorting Speed:</Typography>
                            <Select
                                style={{
                                    marginRight: 5,
                                    height: 30,
                                    width: 100,
                                    fontSize: 10,
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    left: 7,
                                    marginBottom: 13,
                                }}
                                value={props.sortingSpeedLabel}
                                label="Age"
                                onChange={(event) => {
                                    props.setSortingSpeedLabel(event.target.value);
                                }}
                                MenuProps={{
                                    style: {
                                        height: 400, // Set the maximum height of the menu
                                    },
                                }}
                            >
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.FAST}>
                                    Fast
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.MEDIUM}>
                                    Medium
                                </MenuItem>
                                <MenuItem sx={{ fontSize: 10 }} value={SortSpeeds.SLOW}>
                                    Slow
                                </MenuItem>
                            </Select>
                        </Box>
                        <div style={{ position: 'relative', bottom: 42 }}>
                            <Typography fontSize={13}>
                                Note, the tree generator will attempt to randomly place nodes in the canvas until there
                                is no more room. If the number you input contains more nodes than can fit on the screen,
                                the tree generator will construct a tree with as many nodes as possible.
                            </Typography>
                        </div>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
