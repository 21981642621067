export const SORTING_ALG_PROJECT_PATH = 'sorting-algorithm-visualizer';
export const PATHFINDER_PROJECT_PATH = 'pathfinder';
export const TREE_PROJECT_PATH = 'binary-tree-traverser';
export const BLOG_PATH = 'blog';
export const HOME_PATH = '/';
export const BASE_API_PATH =
    window.location.hostname === 'mikebrauninger.com'
        ? 'https://quuy709ivh.execute-api.us-east-1.amazonaws.com/prod'
        : 'https://1faykhomu5.execute-api.us-east-1.amazonaws.com/prod';
export const PROD_MEASUREMENT_ID = 'G-HB5XREV2NY';
export const BETA_MEASUREMENT_ID = 'G-0EZHTFRHC5';
