import { AlgorithmStep, States } from './Types';

export const CANVAS_WIDTH_PERCENTAGE = window.innerWidth <= 768 ? 91.5 : 80;
export const CANVAS_WIDTH_PX = window.innerWidth <= 768 ? 0.915 * window.innerWidth : 0.8 * window.innerWidth;
export const CANVAS_HEIGHT_PERCENTAGE = 768 ? 60 : 70;
export const ARRAY_Y = 200;
export const RECTANGLE_WIDTH = window.innerWidth <= 768 ? 30 : 40;
export const RECTANGLE_HEIGHT = window.innerWidth <= 768 ? 60 : 80;
export const FONT_SIZE = window.innerWidth <= 768 ? 13 : 17;
export const INITIAL_X = RECTANGLE_WIDTH;
export const SORTED_COLOR = '#AFEEEE';

export const TEST_ARRAY = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 763 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 458 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 87 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 782 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 946 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 145 },
];

export const TEST_ARRAY_2 = [
    { x: 50, y: ARRAY_Y, color: 'white', value: 56 },
    { x: 100, y: ARRAY_Y, color: 'white', value: 284 },
    { x: 150, y: ARRAY_Y, color: 'white', value: 559 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 788 },
    { x: 250, y: ARRAY_Y, color: 'white', value: 550 },
    { x: 300, y: ARRAY_Y, color: 'white', value: 211 },
    { x: 350, y: ARRAY_Y, color: 'white', value: 30 },
    { x: 400, y: ARRAY_Y, color: 'white', value: 514 },
];

export const TEST_ARRAY_3 = [
    { x: 50, y: ARRAY_Y, color: 'white', value: 0 },
    { x: 100, y: ARRAY_Y, color: 'white', value: 1 },
    { x: 150, y: ARRAY_Y, color: 'white', value: 2 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 3 },
    { x: 250, y: ARRAY_Y, color: 'white', value: 4 },
    { x: 300, y: ARRAY_Y, color: 'white', value: 5 },
    { x: 350, y: ARRAY_Y, color: 'white', value: 6 },
    { x: 400, y: ARRAY_Y, color: 'white', value: 7 },
];

export const TEST_ARRAY_4 = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 7 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 6 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 5 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 4 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 3 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 2 },
    { x: 280, y: ARRAY_Y, color: 'white', value: 1 },
    { x: 320, y: ARRAY_Y, color: 'white', value: 0 },
];

export const TEST_ARRAY_5 = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 663 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 258 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 857 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 2 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 346 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 445 },
    { x: 280, y: ARRAY_Y, color: 'white', value: 56 },
    { x: 320, y: ARRAY_Y, color: 'white', value: 184 },
    { x: 360, y: ARRAY_Y, color: 'white', value: 259 },
    { x: 400, y: ARRAY_Y, color: 'white', value: 788 },
];

export const TEST_ARRAY_6 = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 0 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 1 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 2 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 3 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 4 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 5 },
    { x: 280, y: ARRAY_Y, color: 'white', value: 6 },
    { x: 320, y: ARRAY_Y, color: 'white', value: 7 },
];

export const TEST_ARRAY_7 = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 20 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 300 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 100 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 301 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 302 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 303 },
    { x: 280, y: ARRAY_Y, color: 'white', value: 304 },
    { x: 320, y: ARRAY_Y, color: 'white', value: 306 },
];
export const TEST_ARRAY_8 = [
    { x: 40, y: ARRAY_Y, color: 'white', value: 988 },
    { x: 80, y: ARRAY_Y, color: 'white', value: 356 },
    { x: 120, y: ARRAY_Y, color: 'white', value: 149 },
    { x: 160, y: ARRAY_Y, color: 'white', value: 544 },
    { x: 200, y: ARRAY_Y, color: 'white', value: 511 },
    { x: 240, y: ARRAY_Y, color: 'white', value: 887 },
    { x: 280, y: ARRAY_Y, color: 'white', value: 927 },
    { x: 320, y: ARRAY_Y, color: 'white', value: 282 },
    { x: 360, y: ARRAY_Y, color: 'white', value: 631 },
    { x: 400, y: ARRAY_Y, color: 'white', value: 804 },
    { x: 440, y: ARRAY_Y, color: 'white', value: 80 },
    { x: 480, y: ARRAY_Y, color: 'white', value: 488 },
    { x: 520, y: ARRAY_Y, color: 'white', value: 503 },
    { x: 560, y: ARRAY_Y, color: 'white', value: 416 },
    { x: 600, y: ARRAY_Y, color: 'white', value: 79 },
    { x: 640, y: ARRAY_Y, color: 'white', value: 847 },
    { x: 680, y: ARRAY_Y, color: 'white', value: 388 },
    { x: 720, y: ARRAY_Y, color: 'white', value: 60 },
    { x: 760, y: ARRAY_Y, color: 'white', value: 580 },
    { x: 800, y: ARRAY_Y, color: 'white', value: 955 },
    { x: 840, y: ARRAY_Y, color: 'white', value: 57 },
    { x: 880, y: ARRAY_Y, color: 'white', value: 539 },
];

export const TEST_ALGORITHM: AlgorithmStep[] = [
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 200,
        element2Destination: 300,
        element1StartIndex: 0,
        element2StartIndex: 5,
        element1DestinationIndex: 3,
        element2DestinationIndex: 5,
    },
    {
        state: States.RAISING_ELEMENTS,
        element1Destination: 120,
        element2Destination: 40,
        element1StartIndex: 3,
        element2StartIndex: 5,
        element1DestinationIndex: -1,
        element2DestinationIndex: -1,
    },
    {
        state: States.SWITCHING_ELEMENTS,
        element1Destination: 300,
        element2Destination: 200,
        element1StartIndex: 3,
        element2StartIndex: 5,
        element1DestinationIndex: 5,
        element2DestinationIndex: 3,
    },
    {
        state: States.LOWERING_ELEMENTS,
        element1Destination: 200,
        element2Destination: 200,
        element1StartIndex: 3,
        element2StartIndex: 5,
        element1DestinationIndex: 5,
        element2DestinationIndex: 3,
    },
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 250,
        element2Destination: 200,
        element1StartIndex: 3,
        element2StartIndex: 5,
        element1DestinationIndex: 4,
        element2DestinationIndex: 3,
    },
    {
        state: States.RAISING_ELEMENTS,
        element1Destination: 120,
        element2Destination: 40,
        element1StartIndex: 4,
        element2StartIndex: 3,
        element1DestinationIndex: -1,
        element2DestinationIndex: -1,
    },
    {
        state: States.SWITCHING_ELEMENTS,
        element1Destination: 200,
        element2Destination: 250,
        element1StartIndex: 4,
        element2StartIndex: 3,
        element1DestinationIndex: 3,
        element2DestinationIndex: 4,
    },
    {
        state: States.LOWERING_ELEMENTS,
        element1Destination: 200,
        element2Destination: 200,
        element1StartIndex: 4,
        element2StartIndex: 3,
        element1DestinationIndex: 3,
        element2DestinationIndex: 4,
    },
    {
        state: States.RAISING_ELEMENTS,
        element1Destination: 120,
        element2Destination: 40,
        element1StartIndex: 0,
        element2StartIndex: 3,
        element1DestinationIndex: -1,
        element2DestinationIndex: -1,
    },
    {
        state: States.SWITCHING_ELEMENTS,
        element1Destination: 200,
        element2Destination: 50,
        element1StartIndex: 0,
        element2StartIndex: 3,
        element1DestinationIndex: 3,
        element2DestinationIndex: 0,
    },
    {
        state: States.LOWERING_PIVOT,
        element1Destination: 200,
        element2Destination: 200,
        element1StartIndex: 0,
        element2StartIndex: 3,
        element1DestinationIndex: 3,
        element2DestinationIndex: 0,
    },
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 50,
        element2Destination: 150,
        element1StartIndex: 4,
        element2StartIndex: 3,
        element1DestinationIndex: 0,
        element2DestinationIndex: 2,
    },
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 150,
        element2Destination: 150,
        element1StartIndex: 0,
        element2StartIndex: 3,
        element1DestinationIndex: 3,
        element2DestinationIndex: 2,
    },
    {
        state: States.RAISING_ELEMENTS,
        element1Destination: 120,
        element2Destination: 40,
        element1StartIndex: 0,
        element2StartIndex: 2,
        element1DestinationIndex: -1,
        element2DestinationIndex: -1,
    },
    {
        state: States.SWITCHING_ELEMENTS,
        element1Destination: 150,
        element2Destination: 50,
        element1StartIndex: 0,
        element2StartIndex: 2,
        element1DestinationIndex: 2,
        element2DestinationIndex: 0,
    },
    {
        state: States.LOWERING_PIVOT,
        element1Destination: 200,
        element2Destination: 200,
        element1StartIndex: 0,
        element2StartIndex: 2,
        element1DestinationIndex: 2,
        element2DestinationIndex: 0,
    },
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 50,
        element2Destination: 100,
        element1StartIndex: 2,
        element2StartIndex: 2,
        element1DestinationIndex: 0,
        element2DestinationIndex: 1,
    },
    {
        state: States.MOVING_I_AND_J,
        element1Destination: 250,
        element2Destination: 300,
        element1StartIndex: 0,
        element2StartIndex: 1,
        element1DestinationIndex: 4,
        element2DestinationIndex: 5,
    },
];
