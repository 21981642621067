import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import RecommendedArticleWidget from '../Components/RecommendedArticleWidget';
import { BASE_API_PATH } from '../constants';
import { getS3Url } from '../utils';

const useStyles = makeStyles((theme) => ({
    centeredContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 1000,
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
        margin: 'auto',
        flexDirection: 'column',
    },
    image: {
        maxWidth: 750,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        paddingBottom: 5,
    },
    title: {
        fontSize: 30,
    },
}));

interface ImageWithCaptionProps {
    url: string;
    caption: string;
}

function ImageWithCaption(props: ImageWithCaptionProps) {
    const classes = useStyles();

    return (
        <Box className={classes.centeredContent} paddingBottom={2}>
            <img src={props.url} alt={props.url} className={classes.image} />
            <Typography
                variant="h4"
                lineHeight={1.5}
                fontSize={15}
                textAlign={'center'}
                fontStyle={'italic'}
                maxWidth={750}
            >
                {props.caption}
            </Typography>
        </Box>
    );
}

interface ParagraphProps {
    text: string;
}

interface TextComponent {
    type: string;
    text: string;
    link?: string;
}

function suggestedArticles(content: BlogContent, direction: string) {
    const stackDirection = direction === 'column' ? 'column' : 'row';
    return (
        <Stack direction={stackDirection} justifyContent="center" alignItems="center" spacing={2} paddingBottom={2}>
            {content.relatedPosts.map((element) => (
                <RecommendedArticleWidget
                    id={element.id}
                    title={element.title}
                    imageUrl={`${getS3Url()}/${element.s3Key}`}
                    description={element.description}
                />
            ))}
        </Stack>
    );
}

function Paragraph(props: ParagraphProps) {
    const textComponents = parseParagraph(props.text);
    return (
        <Typography variant="h4" lineHeight={1.5} fontSize={20} paddingBottom={3}>
            {textComponents.map((component: TextComponent) => {
                if (component.type === 'string') {
                    return component.text;
                } else if (component.type === 'link') {
                    return (
                        <Link href={component.link} underline="none" color={'green'} target="_blank" rel="noopener">
                            {component.text}
                        </Link>
                    );
                }
            })}
        </Typography>
    );
}

interface BlogBodyElement {
    type: string;
    text: string;
    s3Key?: string;
}

interface BlogContent {
    title: string;
    timestamp: number;
    body: BlogBodyElement[];
    relatedPosts: {
        title: string;
        timestamp: number;
        id: string;
        s3Key: string;
        description: string;
    }[];
}

function isImage(element: BlogBodyElement): element is BlogBodyElement & { type: string; s3Key: string } {
    return element.s3Key !== undefined;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function timestampToHumanReadableDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}

const parseParagraph = (input: string) => {
    const regex = /<<([^,]+),\s*([^>]+)>>/g;
    let result = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(input)) !== null) {
        if (match.index > lastIndex) {
            result.push({ type: 'string', text: input.substring(lastIndex, match.index) });
        }
        result.push({ type: 'link', text: match[1], link: match[2] });
        lastIndex = regex.lastIndex;
    }

    if (lastIndex < input.length) {
        result.push({ type: 'string', text: input.substring(lastIndex) });
    }

    return result;
};

export function BlogPost() {
    const classes = useStyles();
    const query = useQuery();
    const blogPostId = query.get('id');

    const [content, setContent] = useState<BlogContent>({
        title: 'Blog',
        timestamp: 0,
        body: [],
        relatedPosts: [],
    });
    const [loadingPostContent, setLoadingPostContent] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await fetch(`${BASE_API_PATH}/blog/${blogPostId}`);
                const data: BlogContent = await response.json();
                setContent(data);
                setLoadingPostContent(false);
            } catch (error) {
                setError(true);
                setLoadingPostContent(false);
            }
        };
        fetchBlogPosts();
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [blogPostId]);

    useEffect(() => {
        if (content.title !== 'Blog') {
            document.title = `${content.title} - Mike Brauninger`;
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
        }
    }, [content]);

    return (
        <>
            <Helmet>
                <title>{content.title} - Mike Brauninger</title>
            </Helmet>
            <Box className={classes.centeredContent}>
                {loadingPostContent ? (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography variant={'h3'} textAlign={'center'}>
                        {`Encountered error when retrieving data.`}
                    </Typography>
                ) : (
                    <div>
                        <Typography variant={window.innerWidth <= 768 ? 'h3' : 'h2'} textAlign={'center'}>
                            {content.title}
                        </Typography>
                        <Typography fontSize={20} paddingBottom={2} textAlign={'center'}>
                            {timestampToHumanReadableDate(content.timestamp)}
                        </Typography>
                        {content.body.map((element) =>
                            isImage(element) ? (
                                <ImageWithCaption url={`${getS3Url()}/${element.s3Key}`} caption={element.text} />
                            ) : (
                                <Paragraph text={element.text} />
                            ),
                        )}
                        <Typography
                            display={'flex'}
                            paddingBottom={2}
                            fontWeight={'bold'}
                            variant="h4"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                        >
                            Suggested Articles
                        </Typography>
                        <Hidden smDown>{suggestedArticles(content, 'row')}</Hidden>
                        <Hidden smUp>{suggestedArticles(content, 'column')}</Hidden>
                    </div>
                )}
            </Box>
        </>
    );
}
