export interface ArrayElement {
    x: number;
    y: number;
    color: string;
    value: number;
}

export enum States {
    MOVING_I_AND_J = 'movingIAndJ',
    RAISING_ELEMENTS = 'raisingElements',
    SWITCHING_ELEMENTS = 'switchingElements',
    LOWERING_ELEMENTS = 'loweringElements',
    RESETTING_I_AND_J = 'resetting',
    DONE = 'done',
    RAISING_PIVOT = 'raisingPivot',
    SWITCHING_PIVOT = 'switchingPivot',
    LOWERING_PIVOT = 'loweringPivot',
    SHIFTING_ARRAY = 'shiftingArray',
}

export enum Axis {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export interface AlgorithmStep {
    state: States;
    element1Destination: number;
    element2Destination: number;
    element1StartIndex: number;
    element2StartIndex: number;
    element1DestinationIndex: number;
    element2DestinationIndex: number;
}

export enum SortSpeeds {
    VERY_FAST = 'VeryFast',
    FAST = 'Fast',
    MEDIUM = 'Medium',
    SLOW = 'Slow',
}

export enum TraversalTypes {
    BUBBLE_SORT = 'BubbleSort',
    INSERTION_SORT = 'InsertionSort',
    QUICK_SORT = 'QuickSort',
    HEAP_SORT = 'HeapSort',
}
