import { ARRAY_Y, INITIAL_X, RECTANGLE_HEIGHT, RECTANGLE_WIDTH } from './Constants';
import { AlgorithmStep, ArrayElement, States } from './Types';

export function quickSort(array: ArrayElement[]) {
    const duplicateArray = [...array];
    return quickSortHelper(duplicateArray, 0, duplicateArray.length - 1, []);
}

function quickSortHelper(array: ArrayElement[], low: number, high: number, output: AlgorithmStep[]) {
    if (low < high) {
        const patritionResult = partition(array, low, high, output);
        quickSortHelper(array, low, patritionResult.sortIndex - 1, patritionResult.output);
        quickSortHelper(array, patritionResult.sortIndex + 1, high, patritionResult.output);
    }
    return output;
}

function partition(array: ArrayElement[], low: number, high: number, output: AlgorithmStep[]) {
    const pivot = array[low];
    let i = low;
    let j = high;
    output.push({
        state: States.RESETTING_I_AND_J,
        element1Destination: INITIAL_X + low * RECTANGLE_WIDTH,
        element2Destination: INITIAL_X + high * RECTANGLE_WIDTH,
        element1StartIndex: 100000000000000,
        element2StartIndex: 100000000000000,
        element1DestinationIndex: low,
        element2DestinationIndex: high,
    });
    while (i < j) {
        const initialI = i;
        const initialJ = j;
        while (array[i].value <= pivot.value && i < high) {
            i++;
        }
        while (array[j].value > pivot.value) {
            j--;
        }
        if (i < j) {
            output.push({
                state: States.MOVING_I_AND_J,
                element1Destination: INITIAL_X + i * RECTANGLE_WIDTH,
                element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                element1StartIndex: initialI,
                element2StartIndex: initialJ,
                element1DestinationIndex: i,
                element2DestinationIndex: j,
            });
            output.push({
                state: States.RAISING_ELEMENTS,
                element1Destination: ARRAY_Y - RECTANGLE_HEIGHT,
                element2Destination: ARRAY_Y - 2 * RECTANGLE_HEIGHT,
                element1StartIndex: i,
                element2StartIndex: j,
                element1DestinationIndex: j,
                element2DestinationIndex: i,
            });
            output.push({
                state: States.SWITCHING_ELEMENTS,
                element1Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                element2Destination: INITIAL_X + i * RECTANGLE_WIDTH,
                element1StartIndex: i,
                element2StartIndex: j,
                element1DestinationIndex: j,
                element2DestinationIndex: i,
            });
            output.push({
                state: States.LOWERING_ELEMENTS,
                element1Destination: ARRAY_Y,
                element2Destination: ARRAY_Y,
                element1StartIndex: i,
                element2StartIndex: j,
                element1DestinationIndex: j,
                element2DestinationIndex: i,
            });
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
    output.push({
        state: States.MOVING_I_AND_J,
        element1Destination: INITIAL_X + i * RECTANGLE_WIDTH,
        element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
        element1StartIndex: i,
        element2StartIndex: j,
        element1DestinationIndex: j,
        element2DestinationIndex: i,
    });
    if (low < j) {
        output.push({
            state: States.RAISING_ELEMENTS,
            element1Destination: ARRAY_Y - RECTANGLE_HEIGHT,
            element2Destination: ARRAY_Y - 2 * RECTANGLE_HEIGHT,
            element1StartIndex: low,
            element2StartIndex: j,
            element1DestinationIndex: j,
            element2DestinationIndex: low,
        });
        output.push({
            state: States.SWITCHING_ELEMENTS,
            element1Destination: INITIAL_X + j * RECTANGLE_WIDTH,
            element2Destination: INITIAL_X + low * RECTANGLE_WIDTH,
            element1StartIndex: low,
            element2StartIndex: j,
            element1DestinationIndex: j,
            element2DestinationIndex: low,
        });
        output.push({
            state: States.LOWERING_ELEMENTS,
            element1Destination: ARRAY_Y,
            element2Destination: ARRAY_Y,
            element1StartIndex: low,
            element2StartIndex: j,
            element1DestinationIndex: j,
            element2DestinationIndex: low,
        });
    }
    const temp = pivot;
    array[low] = array[j];
    array[j] = temp;
    return { sortIndex: j, output: output };
}

export function bubbleSort(array: ArrayElement[]) {
    const output: AlgorithmStep[] = [];
    const duplicateArray = [...array];
    let end = duplicateArray.length - 1;
    for (let i = 0; i < end; i++) {
        let sorted = true;
        for (let j = 0; j < end - i; j++) {
            if (duplicateArray[j].value > duplicateArray[j + 1].value) {
                sorted = false;
                output.push({
                    state: States.RAISING_ELEMENTS,
                    element1Destination: ARRAY_Y - RECTANGLE_HEIGHT,
                    element2Destination: ARRAY_Y - 2 * RECTANGLE_HEIGHT,
                    element1StartIndex: j,
                    element2StartIndex: j + 1,
                    element1DestinationIndex: j + 1,
                    element2DestinationIndex: j,
                });
                output.push({
                    state: States.SWITCHING_ELEMENTS,
                    element1Destination: INITIAL_X + (j + 1) * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                    element1StartIndex: j,
                    element2StartIndex: j + 1,
                    element1DestinationIndex: j + 1,
                    element2DestinationIndex: j,
                });
                output.push({
                    state: States.LOWERING_ELEMENTS,
                    element1Destination: ARRAY_Y,
                    element2Destination: ARRAY_Y,
                    element1StartIndex: j,
                    element2StartIndex: j + 1,
                    element1DestinationIndex: j + 1,
                    element2DestinationIndex: j,
                });
                let temp = duplicateArray[j + 1];
                duplicateArray[j + 1] = duplicateArray[j];
                duplicateArray[j] = temp;
            }
            if (j < end - i - 1) {
                output.push({
                    state: States.MOVING_I_AND_J,
                    element1Destination: INITIAL_X + (j + 1) * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + (j + 2) * RECTANGLE_WIDTH,
                    element1StartIndex: j,
                    element2StartIndex: j + 1,
                    element1DestinationIndex: j + 1,
                    element2DestinationIndex: j + 2,
                });
            } else {
                if (sorted) {
                    return output;
                }
                output.push({
                    state: States.MOVING_I_AND_J,
                    element1Destination: INITIAL_X,
                    element2Destination: INITIAL_X + RECTANGLE_WIDTH,
                    element1StartIndex: end - i - 1,
                    element2StartIndex: end - i - 2,
                    element1DestinationIndex: 0,
                    element2DestinationIndex: 1,
                });
            }
        }
    }
    return output;
}

export function insertionSort(array: ArrayElement[]) {
    let duplicateArray = [...array];
    const output: AlgorithmStep[] = [];
    for (let i = 1; i < duplicateArray.length; i++) {
        output.push({
            state: States.MOVING_I_AND_J,
            element1Destination: INITIAL_X + i * RECTANGLE_WIDTH,
            element2Destination: INITIAL_X,
            element1StartIndex: i - 1,
            element2StartIndex: 1000000000,
            element1DestinationIndex: i,
            element2DestinationIndex: 0,
        });
        for (let j = 0; j < i; j++) {
            let elementToMove = false;
            if (duplicateArray[j].value > duplicateArray[i].value) {
                elementToMove = true;
                output.push({
                    state: States.MOVING_I_AND_J,
                    element1Destination: INITIAL_X + i * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                    element1StartIndex: i,
                    element2StartIndex: 0,
                    element1DestinationIndex: i,
                    element2DestinationIndex: j,
                });
                output.push({
                    state: States.RAISING_ELEMENTS,
                    element1Destination: ARRAY_Y - RECTANGLE_HEIGHT,
                    element2Destination: ARRAY_Y,
                    element1StartIndex: i,
                    element2StartIndex: j,
                    element1DestinationIndex: i,
                    element2DestinationIndex: j,
                });
                output.push({
                    state: States.SWITCHING_ELEMENTS,
                    element1Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                    element1StartIndex: i,
                    element2StartIndex: j,
                    element1DestinationIndex: j,
                    element2DestinationIndex: j,
                });
                output.push({
                    state: States.SHIFTING_ARRAY,
                    element1Destination: INITIAL_X + (j + 1) * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + i * RECTANGLE_WIDTH,
                    element1StartIndex: j,
                    element2StartIndex: i,
                    element1DestinationIndex: j + 1,
                    element2DestinationIndex: i,
                });
                output.push({
                    state: States.LOWERING_ELEMENTS,
                    element1Destination: ARRAY_Y,
                    element2Destination: ARRAY_Y,
                    element1StartIndex: j,
                    element2StartIndex: i,
                    element1DestinationIndex: j,
                    element2DestinationIndex: j,
                });
                let tempArray: ArrayElement[] = [];
                let iElement = duplicateArray[i];
                let jElement = duplicateArray[j];
                for (let k = 0; k < j; k++) {
                    tempArray.push(duplicateArray[k]);
                }
                tempArray.push(iElement);
                for (let k = j; k < i; k++) {
                    tempArray.push(duplicateArray[k]);
                }
                for (let k = i + 1; k < duplicateArray.length; k++) {
                    tempArray.push(duplicateArray[k]);
                }
                duplicateArray = tempArray;

                break;
            }
            if (!elementToMove) {
                output.push({
                    state: States.MOVING_I_AND_J,
                    element1Destination: INITIAL_X + i * RECTANGLE_WIDTH,
                    element2Destination: INITIAL_X + j * RECTANGLE_WIDTH,
                    element1StartIndex: i,
                    element2StartIndex: 0,
                    element1DestinationIndex: i,
                    element2DestinationIndex: j,
                });
            }
        }
    }
    return output;
}

function heapify(array: ArrayElement[], heapSize: number, i: number, output: AlgorithmStep[]) {
    let largest = i;
    const l = 2 * i + 1;
    const r = 2 * i + 2;

    if (l < heapSize && array[largest].value < array[l].value) {
        largest = l;
    }

    if (r < heapSize && array[largest].value < array[r].value) {
        largest = r;
    }

    if (largest != i) {
        const temp = array[i];
        array[i] = array[largest];
        array[largest] = temp;
        heapify(array, heapSize, largest, output);
    }
}

export function heapSort(array: ArrayElement[]) {
    const heapSize = array.length;

    let output: AlgorithmStep[] = [];
    for (let i = Math.floor(heapSize / 2) - 1; i > -1; i--) {
        heapify(array, heapSize, i, output);
    }

    for (let i = heapSize - 1; i > 0; i--) {
        let temp = array[i];
        array[i] = array[0];
        array[0] = temp;
        heapify(array, i, 0, output);
    }
    console.log(array);
    return output;
}
