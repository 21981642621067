import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const modalStyle = {
    width: window.innerWidth <= 768 ? '70%' : 600,
    height: window.innerWidth <= 768 ? '50%' : 390,
    transform: 'translate(0%, 30%)',
    bgcolor: '#ebfce3',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    margin: 'auto',
};

interface ModalProps {
    modalOpen: boolean;
    setModalOpen: Function;
}

export function InfoModal(props: ModalProps) {
    return (
        <Modal open={props.modalOpen} onClose={() => props.setModalOpen(false)} aria-labelledby="info-modal">
            <Box sx={modalStyle}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => props.setModalOpen(false)}
                    aria-label="close"
                    style={{ position: 'absolute', top: 5, right: 15 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <Typography variant="h4" fontWeight="bold">
                        Overview
                    </Typography>
                    <Typography>
                        This is a project that visualizes sorting algorithms. It features four sorting algorithms:
                        <ul>
                            <li>QuickSort</li>
                            <li>HeapSort</li>
                            <li>InsertionSort</li>
                            <li>BubbleSort</li>
                        </ul>
                        <Typography variant="h4" fontWeight="bold">
                            Project Settings
                        </Typography>
                        This project is pretty straightforward. When you click on the 'Settings' button, you can choose
                        a sorting speed, as well as the algorithm you'd like to visualize. That's it!
                        <br />
                        <br />
                        Clicking the 'New Array' button in the top-right corner of the visualizer will generate a new
                        randomly-ordered array.
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
}
